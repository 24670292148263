// src/services/equipmentManagementService.js

const BASE_URL = 'https://prod-10.swedencentral.logic.azure.com/workflows/62df4bd9fc084ad29afcf436e01a9d03/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=5AtOk3zUZH183NdGivGAb6l0-lxG7lsUoEN2afF1JcQ';

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'An error occurred');
  }
  return response.json();
};

// Refactored apiCall to handle both call and data subscriptions
const apiCall = (operation, data = null) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  const body = {
    operation,
    data: data ? {
      Name: data.name,
      Description: data.description,
      Price: data.price,
      ...(data.id && { Id: data.id })
    } : null
  };

  return fetch(BASE_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  }).then(handleResponse);
};

// Refactored subscription functions to use a generic subscriptionApiCall
// src/services/equipmentManagementService.js

// ...

const subscriptionApiCall = (operation, subscription) => {
  return apiCall(operation, subscription)
    .then(response => {
      if (response.message) {
        // If the response contains only a message, return null for the subscription
        return null;
      }
      if (response.message && response.subscription) {
        return response.subscription;
      }
      throw new Error('Invalid response format');
    });
};

// ...


const deleteSubscriptionApiCall = (operation, id) => {
  return apiCall(operation, { id })
    .then(response => {
      if (response.message) {
        // If the response contains only a message, return the provided id
        return id;
      }
      if (response.message && response.id) {
        return response.id;
      }
      throw new Error('Invalid response format');
    });
};

// Phone Call Subscriptions
export const getPhoneCallSubscriptions = () => apiCall('GetPhoneCallSubscriptions');
export const addPhoneCallSubscription = (subscription) => subscriptionApiCall('AddPhoneCallSubscription', subscription);
export const updatePhoneCallSubscription = (id, subscription) => subscriptionApiCall('UpdatePhoneCallSubscription', { ...subscription, id });
export const deletePhoneCallSubscription = (id) => deleteSubscriptionApiCall('DeletePhoneCallSubscription', id);

// Phone Data Subscriptions  
export const getPhoneDataSubscriptions = () => apiCall('GetPhoneDataSubscriptions');
export const addPhoneDataSubscription = (subscription) => subscriptionApiCall('AddPhoneDataSubscription', subscription);
export const updatePhoneDataSubscription = (id, subscription) => subscriptionApiCall('UpdatePhoneDataSubscription', { ...subscription, id });
export const deletePhoneDataSubscription = (id) => deleteSubscriptionApiCall('DeletePhoneDataSubscription', id);

// Phone Models (unchanged)
export const getPhoneModels = () => apiCall('GetPhoneModels');
export const addPhoneModel = (phoneModel) => apiCall('AddPhoneModel', phoneModel);  
export const updatePhoneModel = (id, phoneModel) => apiCall('UpdatePhoneModel', { ...phoneModel, id });
export const deletePhoneModel = (id) => apiCall('DeletePhoneModel', { id });