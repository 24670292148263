// src/formSubmission.js
const submitForm = async (values, logicAppUrl) => {
    try {
      const response = await fetch(logicAppUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
  
      if (response.ok) {
        console.log('Form data successfully posted to Logic App');
        return true;
      } else {
        console.error('Error posting form data to Logic App');
        return false;
      }
    } catch (error) {
      console.error('Error posting form data to Logic App', error);
      return false;
    }
  };
  
  export default submitForm;