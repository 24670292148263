// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import StartPage from './components/StartPage';
import { OrderEquipment } from './components/EquipmentComponents';
import TeamSelectionPage from './components/TeamSelectionPage';
import { TeamForm } from './components/TeamsComponents';
import AdminPage from './components/AdminPage';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { InteractionType } from '@azure/msal-browser';
import ProtectedRoute from './components/ProtectedRoute'; // Ensure this import is active

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* 
          MsalAuthenticationTemplate manages the authentication flow.
          It will automatically redirect unauthenticated users to the login page.
        */}
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        >
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/order-equipment" element={<OrderEquipment />} />
            <Route path="/create-team" element={<TeamSelectionPage />} />
            <Route path="/create-team/:teamType" element={<TeamForm />} />

            {/* Admin Dashboard Route Protected by ProtectedRoute */}
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute requiredGroup="AAD-Orderforms-admin">
                  <AdminPage />
                </ProtectedRoute>
              }
            />

            {/* Redirect any unknown routes to the home page */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </MsalAuthenticationTemplate>
      </Router>
    </ThemeProvider>
  );
};

export default App;
