// src/components/constants.js

import organisationsteamImage from '../assets/images/organisationsteam.png';
import samverkansteamImage from '../assets/images/samverkansteam.png';
import projektteamImage from '../assets/images/projektteam.png';

export const TEAM_TYPES = [
  {
    id: 'organisationsteam',
    title: 'Organisationsteam',
    description:
      'Teamsgrupp för organisatoriska enheter (kostnadsställe). Gruppen är privat och ska enbart vara tillgänglig till enhetens medarbetare. I gruppen kan verksamhetens specifika rutiner och driftinformation sparas.',
    image: organisationsteamImage,
  },
  {
    id: 'samverkansteam',
    title: 'Samverkansteam',
    description:
      'Teamsgrupp för intern eller extern samverkan. Gruppen är privat och ska enbart vara tillgänglig till medarbetare och/eller partner som ingår i en definierad samverkan. Exempelvis: långsiktiga samarbete med leverantör, instruktioner och kommunikation till användare av ett visst system, Samverkansgrupp mellan olika medarbetare som tillhör olika organisatoriska enheter.',
    image: samverkansteamImage,
  },
  {
    id: 'projektteam',
    title: 'Projektteam',
    description:
      'Teamsgrupp för projekt/uppdrag som är tidsbegränsade. Grupp är privat och ska enbart vara tillgänglig för projektmedarbetare, eventuella externa konsulter och projektintressenter (styrgruppen, controller…). Vid skapande av ett projektteam behöver ett gallringsdatum för gruppen anges.',
    image: projektteamImage,
  },
];

export const TEAM_INFO = {
  organisationsteam: {
    title: 'Organisationsteam',
    infoText: 'Teamsgrupp för organisatoriska enheter.',
    nameLabel: 'Enhetens namn',
    nameHelperText: 'Ange enhetens namn',
  },
  samverkansteam: {
    title: 'Samverkansteam',
    infoText: 'Teamsgrupp för intern eller extern samverkan.',
    nameLabel: 'Samverkansnamn',
    nameHelperText: 'Beskriv teamets syfte eller funktion',
  },
  projektteam: {
    title: 'Projektteam',
    infoText: 'Teamsgrupp för tidsbegränsade projekt.',
    nameLabel: 'Projekt/Uppdragsnamn',
    nameHelperText: 'Beskriv teamets syfte eller funktion',
    archiveDateLabel: 'Arkiveringsdatum',
    archiveDateHelperText: 'Ange arkiveringsdatum efter projektets slut.',
  },
};
