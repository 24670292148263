// src/components/PhoneManagementTab.js
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog, 
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Box,  
  Typography,
  Divider,
  Tabs,
  Tab
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  getPhoneCallSubscriptions,
  getPhoneDataSubscriptions,
  addPhoneCallSubscription,
  addPhoneDataSubscription,
  updatePhoneCallSubscription,
  updatePhoneDataSubscription,
  deletePhoneCallSubscription,
  deletePhoneDataSubscription,
  getPhoneModels,
  addPhoneModel,
  updatePhoneModel,
  deletePhoneModel,
} from '../services/equipmentManagementService';

const PhoneManagementTab = () => {
  // State for tabs
  const [activeSubTab, setActiveSubTab] = useState(0);
  
  // State for data
  const [subscriptions, setSubscriptions] = useState({
    call: [],
    data: []  
  });
  const [phoneModels, setPhoneModels] = useState([]);
  
  // UI state
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemType, setItemType] = useState(null); // 'call', 'data', or 'phone'

  // Transform phone models to ensure proper id field
  const transformPhoneModels = (models) => {
    return models.map(model => ({
      ...model,
      id: model.ItemInternalId || model.Id
    }));
  };

  // Fetch all data on component mount
  const fetchAllData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [callData, dataData, phonesData] = await Promise.all([
        getPhoneCallSubscriptions(),
        getPhoneDataSubscriptions(),
        getPhoneModels()
      ]);
      setSubscriptions({
        call: callData,
        data: dataData
      });
      setPhoneModels(transformPhoneModels(phonesData));
    } catch (err) {
      setError('Det gick inte att hämta data. Försök igen.');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const handleOpenDialog = (type, itemType, item = null) => {
    setDialogType(type);
    setItemType(itemType);
    setEditItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditItem(null);
    setItemType(null);
  };

  const handleSubmit = async (event) => {
  event.preventDefault();
  setError(null);

  const form = event.target;
  const formData = {
    name: form.Name.value,
    description: form.Description.value,
    price: form.Price.value,
  };

  try {
    if (dialogType === 'add') {
      switch (itemType) {
        case 'call':
          await addPhoneCallSubscription(formData);
          break;
        case 'data':
          await addPhoneDataSubscription(formData);
          break;
        case 'phone':
          await addPhoneModel(formData);
          break;
        default:
          throw new Error('Unknown item type');
      }
    } else if (dialogType === 'edit') {
      switch (itemType) {
        case 'call':
          await updatePhoneCallSubscription(editItem.Id, formData);
          break;
        case 'data':
          await updatePhoneDataSubscription(editItem.Id, formData);
          break;
        case 'phone':
          await updatePhoneModel(editItem.Id, formData);
          break;
        default:
          throw new Error('Unknown item type');
      }
    }
    await fetchAllData();
    handleCloseDialog();
  } catch (error) {
    console.error('Error submitting form:', error);
    setError(error.message || 'Det gick inte att spara. Försök igen.');
  }
};

  const handleDelete = async (type, id) => {
    try {
      switch (type) {
        case 'call':
          await deletePhoneCallSubscription(id);
          break;
        case 'data':
          await deletePhoneDataSubscription(id);
          break;
        case 'phone':
          await deletePhoneModel(id);
          break;
        default:
          throw new Error('Invalid item type');
      }
      await fetchAllData();
    } catch (error) {
      console.error('Error deleting item:', error);
      setError('Det gick inte att radera. Försök igen.');
    }
  };

  const subscriptionColumns = [
    { field: 'Name', headerName: 'Namn', width: 200 },
    { field: 'Description', headerName: 'Beskrivning', width: 300 },
    { field: 'Price', headerName: 'Pris', width: 150 },
    {
      field: 'actions',
      headerName: 'Åtgärder', 
      width: 200,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleOpenDialog('edit', params.row.itemType, params.row)}>
            Redigera
          </Button>
          <Button 
            onClick={() => handleDelete(params.row.itemType, params.row.Id)} 
            color="error"
          >
            Radera
          </Button>
        </>
      ),
    },
  ];

  const phoneColumns = [
    { field: 'Name', headerName: 'Namn', width: 200 },
    { field: 'Description', headerName: 'Beskrivning', width: 300 },
    { field: 'Price', headerName: 'Pris', width: 150 },
    {
      field: 'actions',
      headerName: 'Åtgärder',
      width: 200,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleOpenDialog('edit', 'phone', params.row)}>
            Redigera
          </Button>
          <Button 
            onClick={() => handleDelete('phone', params.row.Id)} 
            color="error"
          >
            Radera
          </Button>
        </>
      ),
    },
  ];

  const renderDialog = () => (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>
        {dialogType === 'add' ? 'Lägg till' : 'Redigera'} {
          itemType === 'call' ? 'licens' :
          itemType === 'data' ? 'mobildata' : 'telefon'
        }
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="Name"
            label="Namn"
            fullWidth
            defaultValue={editItem?.Name || ''}
            required
          />
          <TextField
            margin="dense"
            name="Description"
            label="Beskrivning"
            fullWidth
            defaultValue={editItem?.Description || ''}
            required
          />
          <TextField
            margin="dense"
            name="Price"
            label="Pris"
            fullWidth
            defaultValue={editItem?.Price || ''}
            required
            helperText={
              itemType === 'phone' 
                ? "Ange pris som intervall, t.ex. '4800-5500kr'"
                : "Ange pris som t.ex. '79kr/mån'"
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Avbryt</Button>
          <Button type="submit">Spara</Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Telefoni
      </Typography>
      
      <Tabs 
        value={activeSubTab} 
        onChange={(e, newValue) => setActiveSubTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Licenser" />
        <Tab label="Mobildata" />
        <Tab label="Telefoner" />
      </Tabs>

      {activeSubTab === 0 && (
        <Box>
          <Button onClick={() => handleOpenDialog('add', 'call')} sx={{ mb: 2 }}>
            Lägg till licens
          </Button>
          <DataGrid
            rows={subscriptions.call.map(sub => ({ ...sub, itemType: 'call' }))}
            columns={subscriptionColumns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            getRowId={(row) => row.Id}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      )}

      {activeSubTab === 1 && (
        <Box>
          <Button onClick={() => handleOpenDialog('add', 'data')} sx={{ mb: 2 }}>
            Lägg till mobildata
          </Button>
          <DataGrid
            rows={subscriptions.data.map(sub => ({ ...sub, itemType: 'data' }))}
            columns={subscriptionColumns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            getRowId={(row) => row.Id}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      )}

      {activeSubTab === 2 && (
        <Box>
          <Button onClick={() => handleOpenDialog('add', 'phone')} sx={{ mb: 2 }}>
            Lägg till telefon
          </Button>
          <DataGrid
            rows={phoneModels}
            columns={phoneColumns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            getRowId={(row) => row.ItemInternalId || row.Id}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      )}

      {renderDialog()}
    </Box>
  );
};

export default PhoneManagementTab;