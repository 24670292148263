// src/hooks/useOrders.js
import { useState, useEffect } from 'react';
import { getOrderHistory } from '../services/orderService';

const useOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    setError(false);
    try {
      const data = await getOrderHistory();
      setOrders(data);
      setFilteredOrders(data);
    } catch (err) {
      console.error('Error fetching order history:', err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return { orders, filteredOrders, setFilteredOrders, loading, error, fetchOrders };
};

export default useOrders;
