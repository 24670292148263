// src/services/graphApiService.js

/**
 * Function to search for users in Microsoft Graph API.
 * @param {string} query - The search query string.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of user objects.
 */
const searchUsersGraphApi = async (query, accessToken) => {
  console.log('Searching users with query:', query);
  try {
    // Encode the query to handle special characters
    const encodedQuery = encodeURIComponent(query);

    // Construct the Graph API endpoint with OData query parameters
    const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${encodedQuery}')&$select=displayName,mail,id,jobTitle`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.value; // Return the array of users
    } else {
      console.error('Error fetching user suggestions from Microsoft Graph API:', response.status, response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error fetching user suggestions:', error);
    return [];
  }
};

/**
 * Fetch the groups that the authenticated user is a member of.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of group objects.
 */
const getUserGroups = async (accessToken) => {
  try {
    const endpoint = `https://graph.microsoft.com/v1.0/me/memberOf?$select=displayName,id,securityEnabled`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.value; // Array of groups
    } else {
      console.error('Error fetching user groups:', response.status, response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error fetching user groups:', error);
    return [];
  }
};
/**
 * Fetch users with a specific extensionAttribute2 value from Microsoft Graph API.
 * @param {string} accessToken - The access token for authentication.
 * @param {string} attributeValue - The value to search for in extensionAttribute2.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of user objects.
 */
const getUsersByExtensionAttribute2 = async (accessToken, attributeValue) => {
  try {
    // Construct the Graph API endpoint with OData query parameters
    const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=onPremisesExtensionAttributes/extensionAttribute2 eq '${attributeValue}'&$select=displayName,mail,id,jobTitle,onPremisesExtensionAttributes&$count=true`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'ConsistencyLevel': 'eventual', // Add this header
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.value.map(user => ({
        displayName: user.displayName,
        mail: user.mail,
        id: user.id,
        jobTitle: user.jobTitle,
        extensionAttribute2: user.onPremisesExtensionAttributes?.extensionAttribute2 || null,
      }));
    } else {
      const errorData = await response.json();
      console.error('Error fetching users from Microsoft Graph API:', response.status, response.statusText, errorData);
      return [];
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};


export { searchUsersGraphApi, getUserGroups, getUsersByExtensionAttribute2 };