// src/components/OrderHistoryTab.js
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DataGridStyled from './common/DataGridStyled';
import ExportButton from './common/ExportButton';
import theme from '../theme';
import useOrders from '../hooks/useOrders';

const OrderHistoryTab = () => {
  const {
    orders,
    filteredOrders,
    setFilteredOrders,
    loading,
    error,
    fetchOrders,
  } = useOrders();

  const [searchQuery, setSearchQuery] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  // Define columns using useMemo to prevent re-creation on every render
  const columns = useMemo(() => [
    { field: 'TeamName', headerName: 'Namn', width: 300 },
    { field: 'RequestedByUser', headerName: 'Beställare', width: 300 },
    { field: 'CreatedAt', headerName: 'Datum', width: 150 },
    { field: 'TeamType', headerName: 'Teamtyp', width: 150 },
    { field: 'ArchiveDate', headerName: 'Arkiveringsdatum', width: 200 },
  ], []);

  // Apply filters whenever relevant state changes
  useEffect(() => {
    let filtered = [...orders];

    // Global search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(order =>
        Object.values(order).some(
          value => value && value.toString().toLowerCase().includes(query)
        )
      );
    }

    // Date range filter
    if (startDateFilter) {
      filtered = filtered.filter(
        order => new Date(order.CreatedAt) >= startDateFilter.toDate()
      );
    }
    if (endDateFilter) {
      filtered = filtered.filter(
        order => new Date(order.CreatedAt) <= endDateFilter.toDate()
      );
    }

    setFilteredOrders(filtered);
  }, [searchQuery, startDateFilter, endDateFilter, orders, setFilteredOrders]);

  return (
    <>
      {/* Header Section */}
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h6"
          sx={{ mb: 2 }}
        >
          Orderhistorik
        </Typography>
      </Box>

      {/* Export Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <ExportButton
          data={filteredOrders}
          filename="OrdersHistory.xlsx"
          label="Exportera till Excel"
        />
      </Box>

      {/* Filters */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mb: 2,
          justifyContent: 'space-between',
        }}
      >
        {/* Global Search */}
        <TextField
          label="Sök"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ minWidth: 200 }}
        />

        {/* Start Date Filter */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Startdatum"
            value={startDateFilter}
            onChange={(newValue) => setStartDateFilter(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        {/* End Date Filter */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Slutdatum"
            value={endDateFilter}
            onChange={(newValue) => setEndDateFilter(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      {/* Data Grid or Loading/Error States */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          Misslyckades att ladda orderhistorik.
        </Alert>
      ) : (
        <div style={{ height: 600, width: '100%' }}>
          <DataGridStyled
            rows={filteredOrders}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row.TeamId}
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            disableSelectionOnClick
            sortingOrder={['asc', 'desc']}
            sortModel={[]}
          />
        </div>
      )}
    </>
  );
};

export default OrderHistoryTab;
