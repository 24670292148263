// src/components/StartPage.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Paper, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Groups as GroupsIcon,
  PhoneAndroid as PhoneAndroidIcon,
  ArrowForward as ArrowForwardIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import theme from '../theme';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { getUserGroups } from '../services/graphApiService';


const OptionCard = ({ title, description, onClick, icon: Icon }) => (
  <Paper
    onClick={onClick}
    role="button"
    aria-label={title}
    tabIndex={0}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
    sx={{
      cursor: 'pointer',
      p: theme.spacing(3),
      mb: theme.spacing(2),
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
      borderRadius: '12px',
      transition: 'box-shadow 0.3s, transform 0.3s',
      '&:hover, &:focus': {
        transform: 'translateY(-3px)',
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.1)',
      },
      '&:focus': {
        outline: `${2}px solid ${theme.palette.primary.main}`,
        outlineOffset: '2px',
      },
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
      <Icon sx={{ fontSize: 40, color: theme.palette.primary.main, mr: 2 }} />
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
          {description}
        </Typography>
      </Box>
    </Box>
    <ArrowForwardIcon sx={{ color: theme.palette.primary.main, ml: 2 }} />
  </Paper>
);

OptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
};

const StartPage = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;
          const groups = await getUserGroups(accessToken);

          // Check if user is a member of the admin group
          const adminGroupId = '3661bf60-7b91-417a-bbb9-10c249903996'; // AAD-Orderforms-admin group ID
          setIsAdmin(groups.some(group => group.id === adminGroupId));
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      }
    };

    checkAdminStatus();
  }, [accounts, instance]);

  const orderOptions = [
    {
      id: 'create-team',
      title: 'Beställ ett Teams',
      description: 'Lägg en beställning för att skapa ett nytt Teams',
      icon: GroupsIcon,
    },
    {
      id: 'order-equipment',
      title: 'Beställ telefon och abonnemang',
      description: 'Beställ telefoner och abonnemang',
      icon: PhoneAndroidIcon,
    },
    // Add admin option if user is admin
    ...(isAdmin ? [{
      id: 'admin-dashboard',
      title: 'Admin Dashboard',
      description: 'Visa och hantera orderhistorik, behöriga beställare och telefoni',
      icon: DashboardIcon,
    }] : []),
  ];

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 } }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Beställningsformulär
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Välj ett alternativ för att gå vidare
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {orderOptions.map(({ id, title, description, icon }) => (
            <Grid item xs={12} key={id}>
              <OptionCard
                title={title}
                description={description}
                onClick={() => navigate(`/${id}`)}
                icon={icon}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default StartPage;