// src/authConfig.js
export const msalConfig = {
  auth: {
    clientId: '6767b29e-47f3-4cf7-8e1f-3fe6b2029df5',
    authority: 'https://login.microsoftonline.com/2c5f00ba-7122-4b58-ac2c-51d7d3ff56cd',
    redirectUri: window.location.origin,  // This will automatically use the current domain
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.ReadBasic.All', 'GroupMember.Read.All', 'User.Read.All']
};