// src/components/TeamSelectionPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Grid,
} from '@mui/material';
import theme from '../theme';
import { TEAM_TYPES } from './constants';
import TeamCard from './TeamCard';

const TeamSelectionPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Skapa ett nytt Teams
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }} align="center">
          Översikt av Teams typer
        </Typography>
        <Grid container spacing={3}>
          {TEAM_TYPES.map((team) => (
            <Grid item xs={12} key={team.id}>
              <TeamCard
                title={team.title}
                description={team.description}
                onClick={() => navigate(`/create-team/${team.id}`)}
                image={team.image}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default TeamSelectionPage;
