// src/components/ManagersTab.js
import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  Typography,
} from '@mui/material';
import DataGridStyled from './common/DataGridStyled';
import ExportButton from './common/ExportButton';
import useManagers from '../hooks/useManagers';

const ManagersTab = () => {
  const {
    managers,
    loadingManagers,
    error,
    fetchManagers,
  } = useManagers();

  // Define columns using useMemo to prevent re-creation on every render
  const managerColumns = useMemo(() => [
    { field: 'displayName', headerName: 'Namn', width: 200 },
    { field: 'mail', headerName: 'E-post', width: 250 },
    { field: 'jobTitle', headerName: 'Befattning', width: 200 },
  ], []);

  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>Chefer</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <ExportButton
          data={managers}
          filename="Chefer.xlsx"
          label="Exportera till Excel"
        />
      </Box>
      {loadingManagers ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          Misslyckades att ladda chefer.
        </Alert>
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <DataGridStyled
            rows={managers}
            columns={managerColumns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.id}
            disableSelectionOnClick
          />
        </div>
      )}
    </>
  );
};

export default ManagersTab;
