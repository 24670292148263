// src/components/ProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getUserGroups } from '../services/graphApiService';
import { loginRequest } from '../authConfig';
import { CircularProgress, Box, Alert } from '@mui/material';

// Use the group ID for the "AAD-Orderforms-admin" group
const REQUIRED_GROUP_ID = '3661bf60-7b91-417a-bbb9-10c249903996';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkGroupMembership = async () => {
      if (isAuthenticated && accounts.length > 0) {
        try {
          // Acquire an access token for Microsoft Graph
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          // Fetch user groups
          const groups = await getUserGroups(accessToken);

          // Check if the user is a member of the required group by its ID
          const isMember = groups.some(
            (group) => group.id === REQUIRED_GROUP_ID && group.securityEnabled
          );

          setIsAuthorized(isMember);
        } catch (err) {
          console.error('Error acquiring token or fetching groups:', err);
          setError('Failed to verify group membership.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    checkGroupMembership();
  }, [isAuthenticated, accounts, instance]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!isAuthorized) {
    return <Navigate to="/access-denied" replace />;
  }

  return children;
};

export default ProtectedRoute;
