// src/theme.js
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#E67E00', // Orange color used for titles and icons
      light: '#FF9419',
      dark: '#B36200',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#79B456', // Green color (keeping it for potential use)
      light: '#97C878',
      dark: '#5C8B41',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
    background: {
      default: '#FFFFFF', // White background
      paper: '#FFFFFF',
    },
    divider: '#E0E0E0', // Light gray for borders
  },
  typography: {
    fontFamily: '"Arial", "Helvetica", sans-serif',
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem',
      color: '#333333',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.1rem',
      color: '#E67E00', // Orange color for card titles
    },
    subtitle1: {
      fontSize: '1rem',
      color: '#666666',
    },
    body2: {
      fontSize: '0.875rem',
      color: '#333333',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width:600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: '8px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '4px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
});

const theme = responsiveFontSizes(baseTheme);

export default theme;