// src/components/TeamCard.js

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import theme from '../theme';

const TeamCard = memo(({ title, description, onClick, image }) => (
  <Card
    onClick={onClick}
    role="button"
    aria-label={`Create ${title}`}
    tabIndex={0}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
    sx={{
      cursor: 'pointer',
      padding: theme.spacing(2),
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
      borderRadius: '12px',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      '&:hover, &:focus': {
        transform: 'translateY(-3px)',
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.1)',
        outline: 'none',
      },
      '&:focus': {
        outlineOffset: '2px',
        outline: `2px solid ${theme.palette.primary.main}`,
      },
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <img
      src={image}
      alt={title}
      style={{ width: 100, height: 'auto', marginRight: theme.spacing(2) }}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          color: theme.palette.primary.main,
          marginBottom: theme.spacing(1),
        }}
      >
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {description}
      </Typography>
    </CardContent>
    <ArrowForwardIcon sx={{ color: theme.palette.primary.main, ml: 2 }} />
  </Card>
));

TeamCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default TeamCard;
