// src/hooks/useManagers.js
import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { getUsersByExtensionAttribute2 } from '../services/graphApiService';

const useManagers = () => {
  const [managers, setManagers] = useState([]);
  const [loadingManagers, setLoadingManagers] = useState(false);
  const [error, setError] = useState(false);
  const { instance, accounts } = useMsal();

  const fetchManagers = async () => {
    setLoadingManagers(true);
    setError(false);
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const accessToken = response.accessToken;
      const users = await getUsersByExtensionAttribute2(accessToken, 'Attestant');
      setManagers(users);
    } catch (err) {
      console.error('Error fetching Managers:', err);
      setError(true);
    } finally {
      setLoadingManagers(false);
    }
  };

  useEffect(() => {
    fetchManagers();
  }, [instance, accounts]);

  return { managers, loadingManagers, error, fetchManagers };
};

export default useManagers;
